export const GraphQLEndpoint =
  process.env.GRAPHQL_ENDPOINT || "http://api:3000/graphql"

export const SiteURL = process.env.NEXT_PUBLIC_SITE_URL || "https://snow.day"

export const GtmId = process.env.NEXT_PUBLIC_GTM_ID || ""

export const GMapsId = process.env.NEXT_PUBLIC_GMAPS_API || ""

export const VideoCollapseTime = 4

export const HotjarId = process.env.NEXT_PUBLIC_HOTJAR_SITE_ID
  ? Number.parseInt(process.env.NEXT_PUBLIC_HOTJAR_SITE_ID)
  : undefined

export const SUPER_TOKENS_APP_NAME =
  process.env.NEXT_PUBLIC_SUPER_TOKENS_APP_NAME || "Snowday"
export const SUPER_TOKENS_API_DOMAIN =
  process.env.NEXT_PUBLIC_SUPER_TOKENS_API_DOMAIN || ""
export const SUPER_TOKENS_WEBSITE_DOMAIN =
  process.env.NEXT_PUBLIC_SUPER_TOKENS_WEBSITE_DOMAIN || ""
export const SUPER_TOKENS_API_BASE_PATH =
  process.env.NEXT_PUBLIC_SUPER_TOKENS_API_BASE_PATH || "/auth"
export const SUPER_TOKENS_WEBSITE_BASE_PATH =
  process.env.NEXT_PUBLIC_SUPER_TOKENS_WEBSITE_BASE_PATH || "/auth"
export const SUPER_TOKENS_API_GATEWAY_PATH =
  process.env.NEXT_PUBLIC_SUPER_TOKENS_API_GATEWAY_PATH

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
